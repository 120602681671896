import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import Layout from "./Pages/Layout/Layout";
import { ROUTE_PATH } from "./constants/Routes";
import "./App.scss";
import RoleAndUser from "./Pages/RoleUserManagement";
import CreateUser from "./Pages/RoleUserManagement/CreateUser";
import CreateRole from "./Pages/RoleUserManagement/createRole";
import EditRole from "./Pages/RoleUserManagement/EditRole";
import EditUser from "./Pages/RoleUserManagement/EditUser.jsx";
import CommunicationTimeline from './Pages/CommunicationTimeline';
import Profile from "./Pages/Profile/profile"
import SuccessHandler from "./Pages/SucessHanlder/SuccessHandler.jsx";
import { useSelector } from "react-redux";
import PrivateRoute from "./Pages/Layout/ProtectedRoutes/PrivateRoutes.jsx";
import { Fragment } from "react";
import Login from "./Pages/Login/login.jsx";
import Statistcs from "./Pages/Statistcs/index.jsx";
import { AdminConstant } from "./constants/constant.jsx";



function App() {


  const selectLoginData = useSelector((state) => state?.login?.loginData);
  const permissionDTOList = selectLoginData?.permissionDTOList || [];
  const permission = permissionDTOList?.reduce((acc, permission) => {
    const { moduleId, ...rest } = permission;
    acc[moduleId] = rest;
    return acc;
  }, {});
  return (
    <Router>
      <Routes>
        <Route path={ROUTE_PATH.LOGIN}
          element={<Login />}></Route>
        <Route
          path="/*"
          element={
            <Fragment>
              <Layout>
                <Routes>
                  <Route
                    path={ROUTE_PATH.SSO}
                    element={
                      <SuccessHandler />
                    }
                  ></Route>
                  <Route
                    path={ROUTE_PATH.COMMUNICATION_TIMELINE}
                    element={
                      <PrivateRoute
                        isAllowed={
                          permission?.["1"]?.moduleAll ||
                          permission?.["1"]?.moduleDownload ||
                          permission?.["1"]?.moduleView
                        }
                      >
                        <CommunicationTimeline />
                      </PrivateRoute>
                    }
                  ></Route>
                  <Route
                    path={ROUTE_PATH.ROLE_USER}
                    element={
                      <PrivateRoute
                        isAllowed={
                          permission?.["2"]?.moduleCreate ||
                          permission?.["2"]?.moduleAll ||
                          permission?.["2"]?.moduleDownload ||
                          permission?.["2"]?.moduleEdit ||
                          permission?.["2"]?.moduleView ||
                          permission?.["3"]?.moduledelete ||
                          permission?.["3"]?.moduleCreate ||
                          permission?.["3"]?.moduleAll ||
                          permission?.["3"]?.moduleDownload ||
                          permission?.["3"]?.moduleEdit ||
                          permission?.["3"]?.moduleView ||
                          permission?.["3"]?.moduledelete
                        }
                      >
                        <RoleAndUser />
                      </PrivateRoute>
                    }
                  ></Route>
                  <Route
                    path={ROUTE_PATH.ROLE_USER_CREATE_USER}
                    element={
                      <PrivateRoute
                        isAllowed={
                          permission?.["3"]?.moduleCreate
                        }
                      >
                        <CreateUser />
                      </PrivateRoute>
                    }
                  >
                  </Route>
                  <Route
                    path={ROUTE_PATH.ROLE_USER_EDIT_USER}
                    element={
                      <PrivateRoute
                        isAllowed={
                          permission?.["3"]?.moduleEdit
                        }
                      >
                        <EditUser />
                      </PrivateRoute>
                    }
                  >
                  </Route>
                  <Route
                    path={ROUTE_PATH.ROLE_USER_CREATE_ROLE}
                    element={
                      <PrivateRoute
                        isAllowed={
                          permission?.["2"]?.moduleCreate
                        }
                      >
                        <CreateRole />
                      </PrivateRoute>
                    }
                  >
                  </Route>
                  <Route
                    path={ROUTE_PATH.ROLE_USER_EDIT_ROLE}
                    element={
                      <PrivateRoute
                        isAllowed={
                          permission?.["2"]?.moduleEdit
                        }
                      ><EditRole />
                      </PrivateRoute>
                    }
                  >
                  </Route>
                  <Route
                    path={ROUTE_PATH.PROFILE}
                    element={
                      <Profile />
                    }
                  ></Route>
                  <Route
                    path={ROUTE_PATH.STATISTICS}
                    element={
                      <PrivateRoute
                        isAllowed={
                          selectLoginData?.roleName?.toLowerCase() === AdminConstant?.admin ? true : false
                        }
                      >
                        <Statistcs />
                      </PrivateRoute>
                    }
                  ></Route>
                </Routes>
              </Layout>
            </Fragment>
          }
        />
      </Routes>
    </Router>

  );
}

export default App;
