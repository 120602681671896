const CONSTANTS = {
  CHART_TYPE: {
    area: "area",
    bar: "bar",
    line: "line",
  },
  ACTION: {
    VIEW: "view",
    EDIT: "edit",
    DELETE: "delete",
    ADD: "add",
  },
  STATUS: {
    OK: 200,
    CREATED: 201,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    DATA_NOT_FOUND: 404,
    DUPLICATE_RECORD: 409,
    INTERNAL_SERVER_ERROR: 500,
    NOT_APPLICABLE: 406,
    BAD_REQUEST: 400,
    UNPROCESSABLE_ENTITY: 422,
    UNSUPPORTED_MEDIA_TYPE: 415,
  },
  DATE_FORMAT: {
    default: "yyyy-MM-dd",
    date_month: "dd MMM",
    DD_MM_YYYY: "dd/MM/yyyy",
    MM_DD_YYYY: "dd/MM/yyyy",
    year_month: "YYYY-MM",
    month_year: "MMM YYYY",
    full_month_year: "MM YYYY",
    date_short_month: "dd MMM",
  },
};

export const AdminConstant = {
  admin : "admin"
}

export default CONSTANTS;
